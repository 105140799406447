














































































































































import { Component, Prop, Vue, Mixins, Watch } from 'vue-property-decorator';
import { IPaginatedUser, IUser } from '@/interfaces';
import { DataTableHeader } from 'vuetify';
import { UsersMixin } from '@/mixins';
import { IPageInfo } from '@/interfaces/IPageInfo.interface';

@Component
export default class UsersView extends Mixins(UsersMixin) {
  private users: IPaginatedUser[] = [];
  private search: string = '';
  private sortBy: Array<string> = [];
  private sortDesc: boolean = false;
  private role = 'Customer';
  private showUserEditor = false;
  private editing = false;
  private editingUser: IUser | null = null;

  private page: number = 1;
  private itemsPerPage: number = 15;
  private totalItems: number = 0;
  private pageInfo: IPageInfo;

  private headers: DataTableHeader[] = [
    {
      text: 'Full Name',
      value: 'node.firstname',
    },
    {
      text: 'Email',
      value: 'node.email',
    },
    {
      text: 'Phone',
      value: 'node.phone',
    },
    {
      text: 'Address',
      value: 'node.address',
    },
    {
      text: 'City',
      value: 'node.city',
    },
    {
      text: 'Role',
      value: 'node.role',
    },
    {
      text: 'Actions',
      value: '',
      width: '200px',
    },
  ];

  // LifeCycles
  created() {
    this.refreshUsers();
  }

  @Watch('page')
  changePage(newValue, oldValue) {
    this.refreshUsers(newValue - oldValue);
  }
  @Watch('search')
  searchUsers() {
    if (this.search.length > 3 || this.search.length === 0) {
      this.refreshUsers();
    }
  }
  @Watch('role')
  async refreshUsers(direction = 0) {
    const variables: {
      first?: number;
      last?: number;
      after?: string;
      before?: string;
      search: string;
    } = { search: `%${this.search}%` };

    if (direction !== -1) {
      variables.first = this.itemsPerPage;
      variables.after = direction === 1 ? this.pageInfo?.endCursor : '';
    } else {
      // Load Previous Page
      if (this.pageInfo.hasPreviousPage) {
        variables.last = this.itemsPerPage;
        variables.before = this.pageInfo.startCursor;
      } else {
        variables.first = this.itemsPerPage;
      }
    }
    const { users } = await this.getUsersByRole(this.role, variables);

    this.users = users.edges;
    this.pageInfo = users.pageInfo;
    this.totalItems = users.totalCount;
  }

  onEditUser(user: IUser) {
    this.editingUser = user;
    this.editing = true;
  }
  async onDisableUser(user: IUser) {
    try {
      await this.disableUser(user.id);
      this.$notify({
        group: 'main',
        type: 'success',
        title: 'User Disabled',
        text: `${user.firstname} ${user.lastname} was disabled`,
      });
      user.isActive = false;
    } catch (e) {
      this.$notify({
        group: 'main',
        type: 'error',
        title: 'Error',
        text: 'Unable to disable user',
      });
    }
  }
  async onActivateUser(user: IUser) {
    try {
      await this.activateUser(user.id);
      this.$notify({
        group: 'main',
        type: 'success',
        title: 'User Activated',
        text: `${user.firstname} ${user.lastname} was activated`,
      });
      user.isActive = true;
    } catch (e) {
      this.$notify({
        group: 'main',
        type: 'error',
        title: 'Error',
        text: 'Unable to activate user',
      });
    }
  }
  onCancelEditing() {
    this.editingUser = null;
    this.editing = false;
  }

  onUserSaved() {
    this.showUserEditor = false;
    this.editing = false;
    this.refreshUsers();
  }
}
